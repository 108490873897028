<div class="flex justify-between items-center flex-wrap h-1/2 bg-[#F8F9FA]">
    <div class="flex items-center flex-wrap">
        <div class="m-0 p-0 place-items-center flex">
            <a [routerLink]="['/dashboard']" aria-label="Go to Dashboard" title="Dashboard">
                <img src="/assets/svg/hezkytech_logo.svg" style="-webkit-user-drag: none;" class="w-40" alt="hezkytech"
                    loading="eager" decoding="sync" crossorigin="anonymous">
            </a>
        </div>
        <ul nz-menu nzMode="horizontal" class="hidden desktop:block border-none bg-[#F8F9FA]">
            <li nz-menu-item nzMatchRouter>
                <a [routerLink]="['/dashboard']" aria-label="Go to Dashboard" title="Dashboard">Dashboard</a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="!commonSvc.roleService.hasAccess([4])">
                <a [routerLink]="['/assets']" aria-label="Go to Assets" title="Assets">Assets</a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="!commonSvc.roleService.hasAccess([4])">
                <a [routerLink]="['/incidents']" aria-label="Go to Incidents" title="Incidents">Incidents</a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="!commonSvc.roleService.hasAccess([3,4])">
                <a [routerLink]="['/reports']" aria-label="Go to Reports" title="Reports">Reports</a>
            </li>
            <li nz-submenu [nzTitle]="admin" *ngIf="commonSvc.roleService.hasAccess([0,1,2])">
                <ul>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/group-management']">Group Management</li>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/product-management']"
                        *ngIf="commonSvc.roleService.hasAccess([0,1,2])">Product Management</li>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/user-management']"
                        *ngIf="commonSvc.roleService.hasAccess([0,1,2])">User Management</li>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/device-management']"
                        *ngIf="commonSvc.roleService.hasAccess([0])">Device Management</li>
                </ul>
            </li>
            <ng-template #admin>
                <span class="hover:text-blue-500">Admin</span>
                <i nz-icon nzType="caret-down" style="font-size: 12px;" class="ml-1" nzTheme="outline"></i>
            </ng-template>
        </ul>
    </div>
    <div class="flex items-center gap-2">
        <div class="hidden phone:flex mini:flex tablet:flex items-center gap-2">
            <nz-badge [nzDot]="notificationCount > 0 ? true  :false">
                <button nz-button nzType="default" nz-popover nzPopoverPlacement="bottomRight" [nzPopoverTitle]="notificationHeader"
                    [(nzPopoverVisible)]="noTfVisible" (nzPopoverVisibleChange)="noTfChange($event)"
                    (click)="noTfVisible = !noTfVisible " nzPopoverTrigger="click" [nzPopoverContent]="notfTemplate"
                    class="border-[#D9D9D9] cursor-pointer" >
                    <!-- [ngClass]="notificationCount > 0 ? 'text-danger' : 'text-primary'" -->
                    <i nz-icon nzType="hezky:Notification" class="hover:text-primary text-base"></i>
                </button>
            </nz-badge> 
            <button nz-button nz-popover [(nzPopoverVisible)]="locationVisible" nzPopoverTrigger="click" nzType="default"
                nzPopoverTitle="Select Location" [nzPopoverContent]="contentTemplate" (click)="locationVisible = !locationVisible"
                nzPopoverPlacement="bottomRight" class="border-[#D9D9D9] cursor-pointer">
                <i nz-icon [nzType]="'hezky:Location'" class="text-[#252524] hover:text-primary text-lg"></i>
            </button>
            <ng-template #contentTemplate>
                <div class="w-min-[300px]">
                    <ng-container *ngIf="treeData">
                        <app-location-tree [treeData]="treeData" [nodes]="nodes" [treeType]="'tree-view-search'" (nodeSelectEvent)="locationVisible = false"/>
                    </ng-container>
                </div>
            </ng-template>
        </div>
        <ul nz-menu nzMode="horizontal" class="border-none bg-[#F8F9FA] hidden tablet:flex">
            <li nz-submenu [nzTitle]="profileDropdown" nzPlacement="bottomRight" aria-label="Profile">
                <ul class="rounded-md shadow-md">
                    <li nz-menu-item [routerLink]="['/account-settings']">
                        <i class="mr-2" nz-icon nzType="user" nzTheme="outline"></i> Account Settings
                    </li>
                    <li nz-menu-item [routerLink]="['/get-support']">
                        <i class="mr-2 rotate-180" nz-icon nzType="phone" nzTheme="outline"></i> Get Support
                    </li>
                    <li nz-menu-item routerLink="/auth/login" (click)="logoutHandler()">
                        <i class="mr-2" nz-icon [nzType]="'hezky:Sign_Out'" nzTheme="outline"></i> Logout
                    </li>
                </ul>
            </li>
        </ul>
        <ng-template #profileDropdown>
            <span class="text-sm font-medium text-gray-600">{{ commonSvc['user_data']['first_name'] }}</span>
            <i nz-icon nzType="caret-down" style="font-size: 12px;" class="ml-1" nzTheme="outline"></i>
        </ng-template>
        <div class="items-center px-2 desktop:hidden tablet:flex phone:flex mini:flex">
            <i nz-icon nzType="menu-fold" nzTheme="outline" class="text-xl text-gray-700" (click)="drawer = !drawer"
                aria-label="Menu" role="button" title="Menu"></i>
        </div>
    </div>
</div>

<nz-drawer [nzWidth]="250" [nzClosable]="false" [nzVisible]="drawer" (nzOnClose)="drawer = false" nzPlacement="left">
    <ng-container *nzDrawerContent>
        <ul nz-menu nzMode="inline" class="border-none w-100">
            <li nz-submenu [nzTitle]="commonSvc['user_data']['first_name']" nzPlacement="bottomRight" class="tablet:hidden"
                aria-label="Profile">
                <ul>
                    <li nz-menu-item [routerLink]="['/account-settings']">
                        <i class="mr-2" nz-icon nzType="user" nzTheme="outline"></i> Account Settings
                    </li>
                    <li nz-menu-item [routerLink]="['/get-support']">
                        <i class="mr-2 rotate-180" nz-icon nzType="phone" nzTheme="outline"></i> Get Support
                    </li>
                    <li nz-menu-item routerLink="/auth/login" (click)="logoutHandler()">
                        <i class="mr-2" nz-icon [nzType]="'hezky:Sign_Out'" nzTheme="outline"></i> Logout
                    </li>
                </ul>
            </li>
            <li nz-menu-item nzMatchRouter>
                <a [routerLink]="['/dashboard']" aria-label="Go to Dashboard" title="Dashboard">Dashboard</a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="!commonSvc.roleService.hasAccess([4])">
                <a [routerLink]="['/assets']" aria-label="Go to Assets" title="Assets">Assets</a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="!commonSvc.roleService.hasAccess([4])">
                <a [routerLink]="['/incidents']" aria-label="Go to Incidents" title="Incidents">Incidents</a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="!commonSvc.roleService.hasAccess([3, 4])">
                <a [routerLink]="['/reports']" aria-label="Go to Reports" title="Reports">Reports</a>
            </li>
            <li nz-submenu nzTitle="Admin" *ngIf="commonSvc.roleService.hasAccess([0,1])">
                <ul>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/group-management']"
                        *ngIf="commonSvc.roleService.hasAccess([0])">Group Management</li>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/product-management']"
                        *ngIf="commonSvc.roleService.hasAccess([0,1,2])">Product Management</li>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/user-management']"
                        *ngIf="commonSvc.roleService.hasAccess([0,1,2])">User Management</li>
                    <li nz-menu-item nzMatchRouter [routerLink]="['admin/device-management']"
                        *ngIf="commonSvc.roleService.hasAccess([0])">Device Management</li>
                </ul>
            </li>
        </ul>
    </ng-container>
</nz-drawer>

<ng-template #notificationHeader>
    <div class="flex justify-between py-2">
        <h3>Notifications</h3>
        <div class="flex justify-end" *ngIf="notifications && notifications.length > 0">
            <a class="text-primary mr-4" (click)="this.markAllAsRead()">Mark all as read</a>
            <a (click)="this.noTfVisible = false"><i nz-icon nzType="close"></i></a>
        </div>
    </div>
</ng-template>

<ng-template #notfTemplate>
    <app-notification [unreadCount]="notificationCount" (refreshClick)="refreshNotifications()" [notifications]="notifications"
    (markedClick)="markNotificationAsRead($event)" (routeToNavigation)="routetoNavigations($event)"></app-notification>
</ng-template>